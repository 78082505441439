<template>
    <div class="bg-slate-50 min-h-screen">
        <div
            class="relative z-10"
            aria-modal="true"
        >
            <Transition>
                <div
                    v-if="mobileMenuOpen"
                    class="fixed inset-0 bg-gray-900/80 sm:hidden"
                    @click="mobileMenuOpen = false"
                />
            </Transition>

            <div
                class="mr-16 fixed inset-y-0 flex transition-all flex-1"
                :class="{
                    'translate-x-0 w-64': mobileMenuOpen,
                    '-translate-x-full w-full sm:translate-x-0 sm:w-24': !mobileMenuOpen
                }"
            >
                <Transition>
                    <div
                        v-if="mobileMenuOpen"
                        class="sm:hidden flex absolute left-full top-0 w-16 justify-center pt-5"
                        @click="mobileMenuOpen = false"
                    >
                        <button
                            type="button"
                            class="-m-2.5 p-2.5"
                        >
                            <span class="sr-only">Close sidebar</span>
                            <XMarkIconMini class="h-6 w-6 text-white" />
                        </button>
                    </div>
                </Transition>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div class="flex h-16 shrink-0 items-center">
                        <img
                            class="h-8 w-auto"
                            src="https://abcreche.com/img/icon.png"
                            alt="ABCreche"
                        >
                    </div>
                    <nav
                        class="flex flex-1 flex-col"
                        aria-label="primary-navigation"
                    >
                        <ul class="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul class="-mx-2 space-y-1">
                                    <li>
                                        <nuxt-link
                                            href="/tablet"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <HomeIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Dashboard</span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/babies"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <Icon
                                                name="lucide:baby"
                                                class="icon stroke15"
                                            />
                                            <span v-if="mobileMenuOpen">Enfants</span>
                                        </nuxt-link>
                                    </li>
                                    <li v-if="$creche.type === 'aes'">
                                        <nuxt-link
                                            href="/tablet/babies/create"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <PlusIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Inscrire un enfant</span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/attendances"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <ClockIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Arrivées et Départs</span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/activity"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <RssIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Activités</span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/messages"
                                            class="nav-item relative"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <ChatBubbleOvalLeftEllipsisIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Messagerie</span>

                                            <span
                                                v-if="unreadThreads"
                                                class="absolute z-10 flex items-center justify-center w-5 h-5 text-white rounded-full top-0 right-1 bg-primary-600"
                                                style="font-size: 11px; line-height: 8px;"
                                            >
                                                <span class="group-hover:animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-600 opacity-0 group-hover:opacity-45" />
                                                {{ unreadThreads > 9 ? '9+' : unreadThreads }}
                                            </span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/social"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <MegaphoneIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Publications</span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/staff"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <Icon
                                                name="clarity:id-badge-line"
                                                class="icon stroke15"
                                            />
                                            <span v-if="mobileMenuOpen">Pointage Personnel</span>
                                        </nuxt-link>
                                    </li>
                                    <li>
                                        <nuxt-link
                                            href="/tablet/scan"
                                            class="nav-item"
                                            :class="{
                                                'justify-center': !mobileMenuOpen
                                            }"
                                            active-class="active"
                                        >
                                            <QrCodeIconOutline class="icon" />
                                            <span v-if="mobileMenuOpen">Scan QR Code</span>
                                        </nuxt-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="mt-auto space-y-2">
                                <nuxt-link
                                    v-if="!$user?.staff?.access_tablet_only"
                                    to="/"
                                    class="nav-item"
                                >
                                    <ComputerDesktopIconMini class="icon" />
                                    <span v-if="mobileMenuOpen">Mode Gestion</span>
                                </nuxt-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

        <div
            class="min-h-screen flex flex-col"
            :class="{
                'sm:pl-24': !mobileMenuOpen,
                'sm:pl-64': mobileMenuOpen
            }"
        >
            <div class="top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button
                    type="button"
                    class="-m-2.5 p-2.5 text-gray-700"
                    @click="mobileMenuOpen = !mobileMenuOpen"
                >
                    <span
                        v-if="!mobileMenuOpen"
                        class="sr-only"
                    >Open sidebar</span>
                    <Bars3IconSolid
                        v-if="!mobileMenuOpen"
                        class="h-6 w-6"
                    />
                    <span
                        v-if="mobileMenuOpen"
                        class="sr-only"
                    >Close sidebar</span>
                    <XMarkIconMini
                        v-if="mobileMenuOpen"
                        class="h-6 w-6"
                    />
                </button>

                <div class="flex flex-1 gap-x-4 self-stretch">
                    <CrecheTopSearch />
                    <div class="flex items-center gap-x-4">
                        <UserNotifications />

                        <UserDropdown v-if="$user" />
                    </div>
                </div>
            </div>

            <main class="py-4 px-4 sm:px-6 relative grow lg:py-6 lg:px-8">
                <suspense>
                    <slot />
                </suspense>
            </main>
            <UiDialog />
            <UiToastWrapper />
        </div>
    </div>
</template>

<script>
import { loadingMixin } from 'abcreche-ui'
import ChatRepository from '~/repositories/Shared/ChatRepository.js'

export default {
    mixins: [loadingMixin],
    data () {
        return {
            mobileMenuOpen: false,
            unreadThreads: 0
        }
    },

    watch: {
        $route () {
            this.mobileMenuOpen = false
        }
    },

    mounted () {
        if (this.$user) {
            this.$echo.private('messages.user.' + this.$user.uuid)
                .on('message.created', (e) => {
                    this.fetchThreads()
                })
        }
    },

    created () {
        this.fetchThreads()
    },

    methods: {
        fetchThreads (append = false) {
            ChatRepository.fetchThreads({ page: this.page })
                .then((response) => {
                    this.threads = append
                        ? [
                                ...this.threads,
                                ...response.data
                            ]
                        : response.data

                    this.unreadThreads = response.total_unread
                })
                .catch(error => this.pageError(error))
        }
    }
}
</script>
